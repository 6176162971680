/* eslint-disable */
import HttpService from '../services/HttpService';
export default class ContractService {
  constructor() {
    this._httpService = new HttpService(`salesforce`);
  }

  async FindbyDescribe() {
    if (!(sessionStorage.getItem("describe-contract"))) {
      var describe = await this._httpService.get('/contract/describe');
      sessionStorage.setItem('describe-contract', JSON.stringify(describe.data));
    }
  }

  dsDescribe(_describe, name, campo) {
    if (!_describe) {
      _describe = JSON.parse(sessionStorage.getItem("describe-contract"));
    }

    if (
        _describe &&
        _describe.fields &&
        _describe.fields.some(s => s.name == campo)
    ) {
      var field = _describe.fields.filter(s => s.name == campo)[0];
      if (field) {
        if (field.picklistValues && field.picklistValues.length > 0) {
          if (field.picklistValues.some(s => s.value == name)) {
            return field.picklistValues.filter(s => s.value == name)[0].label;
          }
        }
      }
    }
    return name;
  }

}
