/* eslint-disable */
import HttpService from '../HttpService'
import $auth from "@/common/auth";

export default class CaseService {
  constructor() {
    this._httpService = new HttpService(`salesforce`);
  }

  async FindAll() {
    return await this._httpService.get('/case');
  }
  async FindById(id) {
    return await this._httpService.get('/case/findAllWithoutCheckPermissions', { id });
  }

  async FindByParam(path) {
    return await this._httpService.get('/case' + path);
  }

  async FindAllByParam(path) {
    return await this._httpService.get('/case/findAllWithoutCheckPermissions' + path);
  }

  async Create(data) {
    return await this._httpService.post('/case', data);
  }

  async CreateCaseWithAttachment(data) {
    return await this._httpService.post('/case/case_with_attachment', data);
  }

  async CreateCaseEvent(data) {
    return await this._httpService.post('/case/case_event', data);
  }

  async FindbyDescribe() {
    if (!(sessionStorage.getItem("describe-case"))) {
      var describe = await this._httpService.get('/case/describe');
      sessionStorage.setItem('describe-case', JSON.stringify(describe.data));
    }
  }

  dsDescribe(_describe, name, campo) {
    if (
        _describe &&
        _describe.fields &&
        _describe.fields.some(s => s.name == campo)
    ) {
      var field = _describe.fields.filter(s => s.name == campo)[0];
      if (field) {
        if (field.picklistValues && field.picklistValues.length > 0) {
          if (field.picklistValues.some(s => s.value == name)) {
            return field.picklistValues.filter(s => s.value == name)[0].label;
          }
        }
      }
    }
    return name;
  }

  async FindNextPage(url) {
    let rq = {
      url: url
    }

    return await this._httpService.get('/case/more', rq);
  }

  async countCaseTotals(){
    const result = {
      pendingCases: 0,
      openedCases: 0,
      totalCases: 0
    }


    if(!$auth.user().beneficiaries){
      return result;
    }

    const params = [];
    $auth.user().beneficiaries.forEach(beneficiary =>{
      params.push({
        label: 'beneficiaryId',
        value: beneficiary.beneficiaryId,
      })
    })

    const responses = await this._httpService.all('/case/countCaseTotalsByBeneficiaryId', params);
    responses.forEach(response =>{
      result.pendingCases += response.data.pendingCases;
      result.openedCases += response.data.openedCases;
      result.totalCases += response.data.totalCases;
    })

    return result;
  };

  async findAllClosedCases(){
    return this.findAllCasesByServiceURL('/case/findAllClosedCasesByBeneficiaryId');
  };


  async findAllOpenedCases(){
    return this.findAllCasesByServiceURL('/case/findAllOpenedCasesByBeneficiaryId');
  };

  async findAllCasesByServiceURL(path){

    const result =[];

    if(!$auth.user().beneficiaries){
      return result;
    }
    const params = [];
    $auth.user().beneficiaries.forEach(beneficiary =>{
      params.push({
        label: 'beneficiaryId',
        value: beneficiary.beneficiaryId,
      })
    })

    const responses = await this._httpService.all(path, params);
    responses.forEach(response =>{
      result.push(...response.data.records);
    })

    return result;
  }

}
