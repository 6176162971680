<template>
  <v-container fluid>
    <CadastroDialog :dialog.sync="dialog"/>
    <v-card>
      <v-form id="ticketForm" ref="ticketForm" @submit.prevent="createTicket">
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex xs12 sm6 lg6 xl6>
              <v-text-field :value="caseData.subject" label="Solicitação" readonly/>
            </v-flex>
            <v-flex xs12>
                <v-select
                    :items="contracts"
                    v-model="caseData.contract"
                    label="Benefício"
                    placeholder="Selecione um benefício"
                    outlined
                    :rules="[rules.required]"
                />
            </v-flex>
            <v-flex xs12>
              <v-textarea
                      v-model="caseData.description"
                      label="Solicitação"
                      placeholder="Descreva sua Solicitação"
                      :rules="[rules.required]"
                      auto-grow
                      hide-details
                      class="pb-5"
              />
            </v-flex>
          </v-layout>
          <v-flex xs12>
            <form-file-upload
                    ref="customFileUpload"
                    :maximum="10"
                    :limit-file-size="12"
                    @update="setAttachments"
            />
          </v-flex>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer v-if="!isMobileSize"/>
        <v-btn :block="isMobileSize" color="primary" type="submit" form="ticketForm" :loading="busy" :disabled="busy">
          Solicitar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "@/common/formrules";
import { callsfrest } from "@/common/sfrest";
import CadastroDialog from "@/view/app/cadastroDialog.vue";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import $auth from "../../common/auth";
import { loadContractsByConnectCompanyConnectionUserId } from "../../common/euprotegido-service"
import ContractService from "../../common/ContractService";
import CaseService from "../../services/salesforce/CaseService";

export default {
  name: 'AtendimentoInserir',
  components: { FormFileUpload, CadastroDialog },
  props: {
    solicitacao: null
  },
  data: () => ({
    defaultErrorObject: { text: "Erro ao inserir", type: "error" },
    caseData: {
      account: null,
      subject: null,
      description: '',
      comment: '',
      attachments: [],
      contract : null
    },
    contracts: [],
    rules: formrules,
    busy: false,
    dialog: false,
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(['isMobileSize']),
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    setAttachments(attachments) {
      this.caseData.attachments = attachments;
    },
    async loadDescribe() {
      await this._contractservice.FindbyDescribe();
      this.describe = JSON.parse(sessionStorage.getItem("describe-contract"));
    },
    getdescribe(name, campo) {
      return this._contractservice.dsDescribe(this.describe, name, campo);
    },
    async createTicket() {
      /*if (!this.$auth.userIsSF()) {
        this.dialog = true;
        return;
      }*/
      if (!this.$refs.ticketForm.validate()) {
        setTimeout(this.$refs.ticketForm.resetValidation, 3000);
        return;
      }

      this.loading(true);
      const configuredCaseData = {
        contract__c : this.caseData.contract,
        accountId: this.contracts.find(
                (contract) => contract.value === this.caseData.contract
        ).accountID,
        subject: this.caseData.subject,
        origin: "eu_protegido",
        description: this.caseData.description,
        suppliedName: $auth.user().name,
        suppliedEmail: $auth.user().email,
      };

      await this._caseService
          .Create(configuredCaseData)
          .then((response) => {
            this.$router.push(`/atendimento/${response.data}`);
          })
          .catch(() => this.showmsg(this.defaultErrorObject))
          .finally(() => this.loading(false));


      // callsfrest({
      //   method: 'post',
      //   url: '/sobjects/Case',
      //   data: configuredCaseData
      // }).then(async response => {
      //   this.addCommentsToCase(response.id);
      //   await this.addAttachmentsToCase(response.id);
      //   this.$router.push(`/atendimento/${response.id}`);
      // }).catch(() => this.showmsg(this.defaultErrorObject))
      //   .finally(() => this.busy = false);
    },
    addCommentsToCase(caseId) {
      const configuredCaseComments = {
        ParentId: caseId,
        CommentBody: this.caseData.comment,
      };
      callsfrest({
        method: 'post',
        url: '/sobjects/CaseComment',
        data: configuredCaseComments
      }).catch(reason => { throw reason });
    },
    async addAttachmentsToCase(caseId) {
      const documentsVersion = await this.createDocumentsContentVersion(caseId);

      if (documentsVersion) {
        const documentsContentVersion = await this.getDocumentsContentVersion(documentsVersion);

        if (documentsContentVersion) {
          await this.postContentDocumentsLink(documentsContentVersion, caseId);
        }
      }
    },
    async createDocumentsContentVersion(caseId) {
      const promises = this.caseData.attachments.map(file => callsfrest({
        method: 'post',
        url: '/sobjects/ContentVersion',
        data: {
          Title: file.name.split('.')[0],
          PathOnClient: `.${file.name.split('.')[1]}`,
          VersionData: file.body.split(',')[1],
        },
      }));
      const versions = await Promise.all(promises).catch(someError => { throw someError });
      return versions;
    },
    async getDocumentsContentVersion(documentsVersion) {
      const promises = documentsVersion.map(version => callsfrest({
        method: 'get',
        url: `/sobjects/ContentVersion/${version.id}`,
      }));
      const versions = await Promise.all(promises).catch(someError => { throw someError });
      return versions;
    },
    async postContentDocumentsLink(documentsVersion, caseId) {
      const promises = documentsVersion.map(version => callsfrest({
        method: 'post',
        url: '/sobjects/ContentDocumentLink',
        data: {
          LinkedEntityId: caseId,
          ContentDocumentId: version.ContentDocumentId,
        },
      }));
      const versions = await Promise.all(promises).catch(someError => { throw someError });
      return versions;
    },
    async loadContracts(){

      this.loading(true);

      loadContractsByConnectCompanyConnectionUserId(this.$auth.user().id).then(res => {

        var contractList = res.data;
        var thiz = this;
        contractList.forEach(function(c) {
          var texto = c.policy ? c.policy : "";
          texto += c.benefit ? " - " + thiz.getdescribe(c.benefit, "Benefits__c"): "";
          texto += c.carrier ? "/" + c.carrier : "";

          thiz.contracts.push({
            accountID: c.accountId,
            value: c.externalID,
            text: texto,
          });
        });
      }).catch(err => {
        this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
      }).finally(() => this.loading(false));

    }
  },
  async created(){
    this._caseService = new CaseService();
    this._contractservice = new ContractService();
    await this.loadDescribe();
    await this.loadContracts();
  },
  beforeMount() {
    this.caseData.subject = this.solicitacao;
    this.caseData.account = this.$auth.user().sf.id;
  },
};
</script>
